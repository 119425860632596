import Title from "@einhorn/title";
import { Container, Drawer, IconButton, Stack, styled } from "@mui/material";
import ReportList from "../reports/reportList";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
    marginTop: "78px"
}));


export default function ReportsDrawer({ drawerOpen, width, programId, toggleDrawer, currentReportId, setFirstReport }: any) {

    return (
        <Drawer
            sx={{
                width: width,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: width,
                },
            }}
            variant="persistent"
            anchor="right"
            open={drawerOpen}
        >
            <DrawerHeader >
                <Container>
                    <Stack spacing={2} direction="row" justifyContent="right" sx={{ mt: '-35px' }}>
                        <IconButton aria-label="open">
                            <CloseOutlinedIcon onClick={() => toggleDrawer()} />
                        </IconButton>
                    </Stack>
                    <Title>Reports</Title>
                </Container>
            </DrawerHeader>
            <ReportList programId={programId} currentReportId={currentReportId} setFirstReport={setFirstReport} />
        </Drawer>
    )
}