import HighchartsReact from "highcharts-react-official";
import { InsightChart } from "../insightTypes";
import Highcharts from "highcharts";
export default function OpinionInsightsChart({ insight }: any) {
    
    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={getOpinionChartOptions(insight)} />

    )
}


function getOpinionChartOptions(insight: InsightChart): Highcharts.Options {

    const { data, title, description } = insight;

    return {
      title: {
        text: title,
      },
      yAxis: {
        reversedStacks: false,
        title: {
          text: null
        },
        labels: { enabled: false },
        height: 2
      },
      xAxis: {
        categories: data.reportDates,
        reversedStacks: false,
  
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          stacking: "normal",
          dataLabels: {
            enabled: true,
            format: '{point.y:,.0f} %'
          },
        },

    bar:{
        groupPadding: 0,
        pointPadding: 0,


    }
      },
      series: GetOrderedOpinionKeys(data.dataSeries).map((key: string) => {
        return {
          name: key,
          type: "bar",
          data: data.dataSeries[key],
        };
      }),
    };
  }
  
  function GetOrderedOpinionKeys(multi: Record<string, number[]>) {
    const order = [
      "not confident at all",
      "not confident",
      "a little confident",
      "somewhat confident",
      "confident",
      "quite confident",
      "very confident",
      "strongly disagree",
      "disagree",
      "neutral",
      "neither agree nor disagree",
      "agree",
      "strongly agree",
      "never",
      "rarely",
      "sometimes",
      "often",
      "always",
      "very dissatisfied",
      "dissatisfied",
      "neutral",
      "neither satisfied nor dissatisfied",
      "satisfied",
      "very satisfied",
    ];
    return Object.keys(multi).filter((z) => z !== "").sort(function (a, b) {
      return order.indexOf(a.toLowerCase()) - order.indexOf(b.toLowerCase());
    });
  }