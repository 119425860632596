import React, { useEffect, useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Container, Button, Typography, Box, CircularProgress, Alert, Grid, IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import FathomClient from "api/fathomapi";
import './insights.css';
import Highcharts from 'highcharts';
import InsightsModal from './insightsModal';
import { InsightChart, InsightsDataPoint } from './insightTypes';
import CreateIcon from '@mui/icons-material/Create';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RatingInsightsChart from "./charts/ratingInsightsChart";
import OpinionInsightsChart from "./charts/opinionInsightsChart";

export default function InsightsList() {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const { programId } = useParams<{ programId: string }>();
  const [insights, setInsights] = useState<InsightChart[]>([]);
  const [deletingInsights, setDeletingInsights] = useState<Set<number>>(new Set());
  const [loadingInsights, setLoadingInsights] = useState<Set<string>>(new Set());

  const loadInsights = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const apiClient = new FathomClient(await getAccessTokenSilently());
      const response = await apiClient.get(`{clientId}/programs/${programId}/insights`);

      if (response.data && Array.isArray(response.data.insightsCharts)) {
        const loadedInsights = response.data.insightsCharts.map((insight: any) => ({
          dataPointId: insight.Id || insight.dataPointId,
          title: insight.Title || insight.title,
          description: insight.Description || insight.description,
          order: insight.Order || insight.order,
          type: insight.Type || insight.type,
          data: {
            reportDates: insight.ReportDates || insight.data?.reportDates || [],
            dataSeries: insight.DataSeries || insight.data?.dataSeries || {}
          },
          questionMappings: insight.QuestionMappings || insight.questionMappings
        }));
        setInsights(loadedInsights);

        setLoadingInsights(prev => {
          const newSet = new Set(prev);
          loadedInsights.forEach((insight: InsightChart) => {
            if (insight.data.dataSeries && Object.keys(insight.data.dataSeries).length > 0) {
              newSet.delete(insight.dataPointId);
            }
          });
          return newSet;
        });
      } else {
        if (response.status == 204) {
          setEditMode(true);
        } else {
          console.error("Unexpected API response structure:", response.data);
          setError("Unexpected data structure received from the server.");
        }
      }
    } catch (error) {
      console.error("Error fetching insights:", error);
      setError("An error occurred while fetching insights. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, [getAccessTokenSilently, programId]);

  useEffect(() => {
    loadInsights();
  }, [loadInsights]);

  const handleInsightCreated = useCallback((newInsight: Omit<InsightChart, 'dataPointId'>, isLoading: boolean) => {
    const insightWithId: InsightChart = {
      ...newInsight,
      dataPointId: "0000000"
    };
    setInsights(prevInsights => [...prevInsights, insightWithId]);
    if (isLoading) {
      setLoadingInsights(prev => new Set(prev).add(insightWithId.dataPointId));

      // Start polling for the new insight data
      const pollInterval = setInterval(async () => {
        await loadInsights();
        if (!loadingInsights.has(insightWithId.dataPointId)) {
          clearInterval(pollInterval);
        }
      }, 5000);

      // Clear interval after 2 minutes (24 * 5 seconds) to prevent infinite polling
      setTimeout(() => clearInterval(pollInterval), 120000);
    }
  }, [loadInsights]);

  const viewPrograms = useCallback(() => {
    navigate("/programs/");
  }, [navigate]);

  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleDeleteInsight = useCallback(async (insightOrder: number) => {
    setDeletingInsights(prev => new Set(prev).add(insightOrder));
    try {
      const apiClient = new FathomClient(await getAccessTokenSilently());

      // First, fetch the current insights structure
      const currentInsightsResponse = await apiClient.get(`{clientId}/programs/${programId}/insights/structure`);

      let insightsDataPoints = currentInsightsResponse.data.insightsDataPoints || [];

      // Remove the insight with the matching order
      insightsDataPoints = insightsDataPoints.filter((insight: InsightsDataPoint) => insight.order !== insightOrder);

      // Update the order of remaining insights
      insightsDataPoints = insightsDataPoints.map((insight: InsightsDataPoint, index: number) => ({
        ...insight,
        Order: index
      }));

      // Prepare the request body with the updated insightsDataPoints
      const requestBody = {
        insightsDataPoints: insightsDataPoints
      };

      // Send the updated insights data
      await apiClient.post(`{clientId}/programs/${programId}/insights`, requestBody);

      // Update the local state
      setInsights(prevInsights => {
        const updatedInsights = prevInsights.filter(insight => insight.order !== insightOrder);
        return updatedInsights.map((insight, index) => ({
          ...insight,
          order: index
        }));
      });
    } catch (error) {
      console.error("Error deleting insight:", error);
      setError("An error occurred while deleting the insight. Please try again later.");
    } finally {
      setDeletingInsights(prev => {
        const newSet = new Set(prev);
        newSet.delete(insightOrder);
        return newSet;
      });
    }
  }, [getAccessTokenSilently, programId]);




  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth={false} disableGutters>
      <Box maxWidth="xl" mx="auto">
        <Grid container>
          <Grid xs={2}>
            <Typography variant="h6" sx={{ my: 4, ml: 2 }}>View Insights &nbsp;
            {!editMode &&
              <IconButton onClick={() => setEditMode(true)} sx={{ opacity: "0.6", textTransform: 'none', mt: 0}}>
                <CreateIcon fontSize="small" />
              </IconButton >
            }{editMode &&
              <IconButton onClick={() => setEditMode(false)} sx={{ opacity: "0.6", textTransform: 'none', mt: 0 }}>
                <VisibilityIcon fontSize="small" />
              </IconButton >
            } </Typography></Grid>
        </Grid>
        <Box className="insights-container">
          {insights.map((insight) => (
            <Box key={insight.dataPointId} className="insight-box">
              <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mb={2}>
                <Typography variant="subtitle1">{insight.title} <Typography variant="subtitle2">{insight.type[0].toUpperCase() + insight.type.slice(1)}</Typography></Typography>
                {loadingInsights.has(insight.dataPointId) ? (
                  <CircularProgress size={24} />
                ) : (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleDeleteInsight(insight.order)}
                    disabled={deletingInsights.has(insight.order)}
                    size="small"
                    hidden={!editMode}
                  >
                    {deletingInsights.has(insight.order) ? 'Deleting...' : 'Delete'}
                  </Button>
                )}
              </Box>
              <Box className="insight-chart-container">
                {loadingInsights.has(insight.dataPointId) ? (
                  <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                    <CircularProgress />
                    <Typography variant="body2" sx={{ ml: 2 }}>Generating chart...</Typography>
                  </Box>
                ) : (
                <>
                    {insight.type == "Rating" &&
                      <RatingInsightsChart
                        highcharts={Highcharts}
                        insight={insight} />
                    }
                    {insight.type == "Opinion" &&
                      <OpinionInsightsChart
                        highcharts={Highcharts}
                        insight={insight} />
                    }
                  </>

                )}
              </Box>
            </Box>
          ))}
          <Box className="insight-box add-insight-box" hidden={!editMode} >
            <Button variant="outlined" onClick={handleOpenModal}>
              Add Insight
            </Button>
          </Box>
        </Box>

        <InsightsModal
          open={openModal}
          onClose={handleCloseModal}
          programId={programId || ''}
          onInsightCreated={handleInsightCreated}
          insights={insights}
        />
      </Box>
    </Container>
  );
}
