import React, { useState, useCallback, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Typography, Modal, CircularProgress, List, Checkbox, Tooltip, Alert, TextField, Button, Grid } from "@mui/material";
import FathomClient from "api/fathomapi";
import { InsightChart, InsightsDataPoint, InsightsModalProps, Report, Question } from './insightTypes';


export default function InsightsModal({ open, onClose, programId, onInsightCreated, insights }: InsightsModalProps) {
  const { getAccessTokenSilently } = useAuth0();
  const [reports, setReports] = useState<Report[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedQuestions, setSelectedQuestions] = useState<{ [reportId: string]: string }>({});
  const [selectedQuestionType, setSelectedQuestionType] = useState<string | null>(null);
  const [newInsightTitle, setNewInsightTitle] = useState("");
  const [description, setDescription] = useState("");

  const loadReportsWithQuestions = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const apiClient = new FathomClient(await getAccessTokenSilently());
      const response = await apiClient.get(`{clientId}/programs/${programId}/insights/questions`);
      const { reports } = response.data;

      if (!reports || reports.length === 0) {
        setError("No reports or questions found for this program.");
        setReports([]);
        return;
      }

      const reportsWithQuestions = reports.map((report: any) => ({
        id: report.reportId,
        title: report.title,
        date: report.date,
        questionAnalysis: {
          questionsSummary: report.questionIdsList.map((question: any) => ({
            questionId: question.questionId,
            reportId: report.reportId,
            questionHeader: question.title,
            type: question.type
          }))
        }
      }));

      setReports(reportsWithQuestions);
    } catch (error) {
      console.error("Error fetching reports and questions:", error);
      setError("An error occurred while fetching reports and questions. Please try again later.");
      setReports([]);
    } finally {
      setLoading(false);
    }
  }, [getAccessTokenSilently, programId]);

  const handleQuestionSelect = useCallback((reportId: string, questionId: string, questionType: string) => {
    setSelectedQuestions(prev => {
      const newState = { ...prev };
      if (newState[reportId] === questionId) {
        delete newState[reportId];
      } else {
        newState[reportId] = questionId;
      }
      return newState;
    });
  }, []);

  const canContinue = useCallback(() => {
    const selectedCount = Object.keys(selectedQuestions).length;
    const hasRatingQuestion = Object.values(selectedQuestions).some(questionId => {
      const question = reports.flatMap(r => r.questionAnalysis.questionsSummary).find(q => q.questionId === questionId);
      return question;
    });
    return selectedCount >= 2 && hasRatingQuestion;
  }, [selectedQuestions, reports]);

  const createInsight = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const apiClient = new FathomClient(await getAccessTokenSilently());

      const selectedQuestionDetails = Object.entries(selectedQuestions).map(([reportId, questionId]) => {
        const report = reports.find(r => r.id === reportId);
        const question = report?.questionAnalysis.questionsSummary.find((q: Question) => q.questionId === questionId);
        return { reportId, questionId, question, reportDate: report?.date };
      });

      // First, fetch the current insights
      const currentInsightsResponse = await apiClient.get(`{clientId}/programs/${programId}/insights/structure`);
      const currentInsights = currentInsightsResponse.data.insightsDataPoints || [];
      console.log('structure', currentInsights);
      const newInsightDataPoint: Omit<InsightsDataPoint, 'dataPointId'> = {
        title: newInsightTitle || "New Insight",
        description: description,
        order: currentInsights.length,
        type: selectedQuestionDetails[0]?.question?.type ?? "rating",
        questionMappings: selectedQuestionDetails.map(({ reportId, questionId }) => ({
          reportId,
          questionId
        }))
      };

      const requestBody = {
        insightsDataPoints: [...currentInsights, newInsightDataPoint]
      };

      const createResponse = await apiClient.post(`{clientId}/programs/${programId}/insights`, requestBody);
      console.log("Create insight response:", createResponse);

      // Convert the created insight to InsightChart type
      const createdInsight: Omit<InsightChart, 'dataPointId'> = {
        title: newInsightDataPoint.title,
        description: newInsightDataPoint.description,
        order: newInsightDataPoint.order,
        type: newInsightDataPoint.type,
        data: {
          reportDates: selectedQuestionDetails.map(q => q.reportDate || ''),
          dataSeries: {} // This will be populated by the backend
        },
        questionMappings: newInsightDataPoint.questionMappings
      };

      // Pass the created insight and a flag indicating it's still processing
      onInsightCreated(createdInsight, true);
      onClose();
    } catch (error) {
      console.error("Error creating insight:", error);
      setError("An error occurred while creating the insight. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, [selectedQuestions, newInsightTitle, getAccessTokenSilently, programId, onInsightCreated, onClose, reports]);

  useEffect(() => {
    if (open) {
      loadReportsWithQuestions();
    }
  }, [open, loadReportsWithQuestions]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box className="modal-content">
        <Typography variant="h6" component="h2">Create Insight</Typography>

        <Grid container>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Insight Title"
              value={newInsightTitle}
              onChange={(e) => setNewInsightTitle(e.target.value)}
              margin="normal"
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Insight description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              margin="normal"
              multiline
              rows={4}
            />
          </Grid>
        </Grid>


        <Typography sx={{ mt: 2, mb: 3 }}>Select questions from the reports below to compare</Typography>

        <Box className="modal-body">
          {loading ? (
            <CircularProgress />
          ) : error ? (
            <Alert severity="error">{error}</Alert>
          ) : reports.length === 0 ? (
            <Alert severity="info">No reports or questions available for this program.</Alert>
          ) : (
            <List>
              {reports.map((report: Report) => (
                <Box key={report.id} className="report-container">
                  <Typography variant="subtitle1">{report.title}</Typography>
                  <Box className="scrollable-questions">
                    {report.questionAnalysis?.questionsSummary?.map((question: Question) => {
                      const isSelected = selectedQuestions[report.id] === question.questionId;
                      return (
                        <Tooltip
                          key={`${report.id}:${question.questionId}`}
                          title={question.questionHeader}
                          arrow
                          placement="top"
                        >
                          <Box
                            className={`question-box`}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleQuestionSelect(report.id, question.questionId, question.type);
                            }}
                          >
                            <Box className="question-content">
                              <Typography variant="body2" className="question-text">
                                {question.questionHeader}
                              </Typography>
                            </Box>
                            <Checkbox
                              checked={isSelected}
                              onChange={(e) => {
                                e.stopPropagation();
                                handleQuestionSelect(report.id, question.questionId, question.type);
                              }}
                              onClick={(e) => e.stopPropagation()}
                              className="question-checkbox"
                            />
                          </Box>
                        </Tooltip>
                      );
                    })}
                  </Box>
                </Box>
              ))}
            </List>
          )}
        </Box>
        <Box className="modal-footer" sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button
            variant="contained"
            onClick={createInsight}
            disabled={!canContinue() || loading || !!error}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
