import React, { useRef } from "react";
import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import HighchartsReact from "highcharts-react-official";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import PollIcon from "@mui/icons-material/Poll";
import ReactMarkdown from "react-markdown";
import "./reportQuestions.css";
import Title from "@einhorn/title";
import RatingChart from "./charts/ratingChart";
import OpinionChart from "./charts/opinionChart";
import MultiChart from "./charts/multiChart";
import MetricsChart from "./charts/metricsChart";
import OpenTextView from "./charts/openText";
import NpsChart from "./charts/npsChart";
import RankingChart from "./charts/rankingChart";
export default function ViewQuestionsReport({ report }: any) {

  const [currentQuestion, setCurrentQuestion] = React.useState(
    report.questionAnalysis.questionsSummary[0],
  );
  const env = process.env.REACT_APP_ENV;

  const breakdownRef = useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (breakdownRef.current) {
      breakdownRef.current.scrollTop = 0;
    }
  }, [currentQuestion]);
  const [currentActiveQuestion, setActiveCurrentQuestion] = React.useState(0);
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const getQuestions = () => {
    return report.questionAnalysis.questionsSummary.map((x: any, i: number) => (
      <Paper
        className={currentActiveQuestion == i ? "activeQuestion" : ""}
        sx={{
          p: 2,
          display: "flex",
          cursor: "pointer",
          flexDirection: "column",
          marginBottom: "20px",
        }}
        key={i}
        onClick={() => {
          setCurrentQuestion(x);
          setActiveCurrentQuestion(i);

          if (breakdownRef.current) {
            breakdownRef.current.scrollTop = 0;
          }
        }}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid sm={12} item>
              <Typography variant="subtitle2">
                <QuestionAnswerIcon
                  sx={{
                    color: "#45C27E",
                    width: "17px",
                    height: "17px",
                    marginRight: "5px",
                    marginTop: "-1px",
                  }}
                />{" "}
                Q{i + 1}
              </Typography>
              <Typography variant="subtitle1">{x.questionHeader}</Typography>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    ));
  };

  return (
    <>
      <br />
      <br />
      <Grid container spacing={2}>
        <Grid item md={8} >
          <Title>{report.title}</Title>
          <Typography variant="subtitle2">&nbsp;&nbsp;{report.programDescription}</Typography>
        </Grid>
        <Grid item md={4} sx={{ marginBottom: "-80px", marginTop: "-50px", height: "200px" }}>
          <MetricsChart env={env} rating={report} chartComponentRef={chartComponentRef} green={false}/>
        </Grid>
      </Grid>
      <br />
      <Paper id="reportTitle" className="reportTile">
        <Typography variant="subtitle1">
          <PollIcon
            sx={{ color: "#6327E6", marginRight: "10px", marginTop: "-2px" }}
          />
          Summary
        </Typography>
        <hr />
        <ReactMarkdown>{report.questionAnalysis.overallSummary}</ReactMarkdown>
      </Paper>
      <br />
      <br />
      <Grid container spacing={4}>
        <br />
        <br />
        <Grid item xs={4} >
          Questions
        </Grid>
        <Grid item xs={8} >
          Breakdown
        </Grid>
        <Grid item xs={4} className="scroll-container questions-container">
          {getQuestions()}
        </Grid>
        <Grid item xs={8} className="scroll-container breakdown-container" ref={breakdownRef}>
          <div className="breakdown-content">
            <Grid container spacing={4}>
              <Grid item lg={6} md={6} sm={6} xs={4} />
              <Grid item xs={6} >
                <MetricsChart env={env} rating={currentQuestion} chartComponentRef={chartComponentRef} green={false}/>
              </Grid>
            </Grid>
            {currentQuestion.multiResponses &&
              currentQuestion.multiResponses?.map((multi: any, i : number) => 
              <MultiChart key={i} report={report} multi={multi} chartComponentRef={chartComponentRef} />)}

            {currentQuestion.opinionResponses &&
              currentQuestion.opinionResponses?.map((multi: any,  i : number) => 
              <OpinionChart key={i} report={report} multi={multi} chartComponentRef={chartComponentRef} />)}

            {currentQuestion.ratingResponses &&
              currentQuestion.ratingResponses?.map((rating: any,  i : number) => 
              <RatingChart key={i} report={report} rating={rating} chartComponentRef={chartComponentRef} />)}

            {currentQuestion.npsResponses &&
              currentQuestion.npsResponses?.map((nps: any,  i : number) => 
              <NpsChart key={i} report={report} nps={nps} chartComponentRef={chartComponentRef} />)}

            {currentQuestion.rankingResponses &&
              currentQuestion.rankingResponses?.map((ranking: any,  i : number) => 
              <RankingChart key={i} report={report} ranking={ranking} chartComponentRef={chartComponentRef} />)}

            <OpenTextView currentQuestion={currentQuestion} />
          </div>
        </Grid>
      </Grid>
    </>
  );
}

