import {
  Button,
  Container,
  Grid,
  LinearProgress,
  Pagination,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import FathomClient from "@api/fathomapi";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import Moment from "moment";
import BarChartIcon from '@mui/icons-material/BarChart';
import BuildIcon from '@mui/icons-material/Build';
import Cookies from "universal-cookie";
import { PagedResult } from "app/types";
import debounce from 'lodash/debounce';
import CreateProgram from "./create/create";

const cookies = new Cookies();

const drawerWidth = 339;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    position: 'relative',
    variants: [
        {
            props: ({ open }) => open,
            style: {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginRight: 0,
            },
        },
    ],
}));


export default function Programs() {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const [programs, setPrograms] = useState({
    data: [],
    paging: {
      length: 0,
      pageSize: 0,
      page: 0,
      total: 0
    }
  } as PagedResult<any>);
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [editProgramId, setEditProgramId] = useState("");
  const [loading, setLoading] = useState(true);
  const mapData = (z: any) => {
    return {
      id: z.id,
      name: z.name,
      description: z.description,
      startDate: z.startDate,
    };
  };

  const IsAdmin = () => {
    return localStorage.getItem("x-fathom-currentRole") == "Admin"
  }

  const createNew = () => {
    setDrawerOpen(true);
  };
  const edit = (id: string) => {
    setEditProgramId(id)
    setDrawerOpen(true);

  };

  const viewReports = (programId: string) => {
    navigate("/programs/" + programId );
  };

  const handleChangePage = (_: React.ChangeEvent<unknown>, newPage: number) => {
    if (newPage != page) {
      setPage(page);
      loadPrograms(search, newPage)
    }
  };

  const handleSearch = debounce((searchTerm: string, pageNo: number) => {
    loadPrograms(searchTerm, pageNo)
  }, 300);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearch(newValue)
    handleChangePage({} as ChangeEvent<unknown>, 1)
    handleSearch(newValue, 1);
  };

  const loadPrograms = async (searchTerm: string, page: number) => {
    const apiClient = new FathomClient(await getAccessTokenSilently());
    const { data } = await apiClient.get(`{clientId}/programs?page=` + page + "&search=" + searchTerm);
    setPrograms(data);
    var mapped = data.data.map(mapData);
    setRows(mapped);
    setLoading(false);
  }

  useEffect(() => {

    // declare the data fetching function
    const fetchData = async () => {
      if (cookies.get("x-fathom-clientId") === undefined) {
        var loc = localStorage.getItem("saved_invite");
        if (loc != undefined) {
          navigate(loc || '')
        }
      } else {
        loadPrograms("", 1)
      }
    };
    setLoading(true);
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [editProgramId]);

  const getPrograms = () => {
    return programs.data.map((x: any) => (
      <Paper className="programOverview" sx={{ p: 2, display: "flex", flexDirection: "column", marginBottom: "20px" }} key={x.id}>
        <Container>
          <Grid container spacing={2} alignItems="center">
            <Grid sm={8} item >
              <Typography
                sx={{
                  marginTop: "10px",
                }}
                variant="h4"
              >
                {x.name}
              </Typography>
              <Typography sx={{
                marginBottom: "20px",
              }} variant="subtitle2">{x.description} <span className="bulletSpacer">•</span><span>{Moment(x.startDateUtc).format("Do MMMM YYYY")}</span></Typography>
            </Grid>
            <Grid sm={4} item textAlign="right">
              <Button variant="outlined" disableElevation size="small" hidden={!IsAdmin()} sx={{
                margin: "0 8px",
              }} onClick={() => edit(x.id)}>
                <BuildIcon fontSize="inherit" />&nbsp; Edit
              </Button>
              <Button variant="contained" disableElevation size="small" sx={{
                margin: "0 8px",
              }} onClick={() => viewReports(x.id)}>
                <BarChartIcon fontSize="inherit" />  &nbsp; Reports
              </Button>
            </Grid>
            <br />
            <br />
            <br />
            <br />
          </Grid>
        </Container>
      </Paper>
    ));
  };

  return (
    <Main>
      <Container>
      <Container>

          <br />
          <Button >Programs</Button>
          <Button variant="outlined" hidden={!IsAdmin()} onClick={() => createNew()} sx={{ float: 'right', marginBottom: '-30px' }}>
            New program
          </Button>
          <br />
          <br />
          {loading && programs.paging.length == 0 &&
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column", marginBottom: "20px" }} >
              <LinearProgress color="secondary" />
              <br />
              <br />
              <br />
            </Paper>
          }


          {!loading &&
            <>
              <Stack spacing={2} direction="row" justifyContent="left" sx={{ marginBottom: '-20px' }}>
                <TextField
                  label="Search"
                  variant="standard"
                  value={search}
                  onChange={handleSearchChange}
                />
              </Stack>
              <Stack spacing={2} direction="row" justifyContent="right" sx={{ marginBottom: '20px' }}>
                <Pagination
                  count={Math.ceil(programs.paging.total / programs.paging.pageSize)}
                  page={programs.paging.page}
                  onChange={handleChangePage}
                  color="primary"
                  variant="outlined"
                  showFirstButton showLastButton
                />
              </Stack>
            </>
          }
          {programs.paging.length == 0 && !loading && (
            <Paper>
              {" "}
              <Container>
                <br />
                <Typography variant="h6">
                  No programs found
                </Typography>
                <br />
              </Container>
            </Paper>
          )}
          {getPrograms()}
      </Container>
      </Container>
      <CreateProgram 
        drawerOpen={drawerOpen} 
        toggleDrawer={() => setDrawerOpen(!drawerOpen)} 
        editProgramId={editProgramId}
      />
      <br />
    </Main>
  );
}
