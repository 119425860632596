import {
    Button,
    Container,
    Grid,
    LinearProgress,
    Paper,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import FathomClient from "@api/fathomapi";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";
import { ReportDto } from "./report";
import ViewQuestionsReport from "./questions/reportQuestions";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import dayjs from "dayjs";
const env = process.env.REACT_APP_ENV;

export default function ViewReport({currentReportId} : any) {
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const [report, setReport] = useState({} as ReportDto);
    const [loading, setLoading] = useState(true);
    const { programId } = useParams();

    useEffect(() => {
        // declare the data fetching function
        const fetchData = async (reportId: string) => {
            if (reportId !== null && reportId != undefined) {
                const apiClient = new FathomClient(await getAccessTokenSilently());
                const { data } = await apiClient.get(`{clientId}/programs/` + programId + `/reports/` + reportId);
                if(data.startDateUtc === undefined){
                    data.startDateUtc = dayjs();
                }
                setReport(data);
                setLoading(false);
                if (data.status === "Complete") {
                    clearInterval(interval)
                }
            }

        };

        const interval = setInterval(() => {
            fetchData(currentReportId)
                // make sure to catch any error
                .catch(console.error);
        }, 30000);
        
        if (currentReportId !== null) {
            setLoading(true);
            // call the function
            fetchData(currentReportId)
                // make sure to catch any error
                .catch(console.error);
        }

    }, [getAccessTokenSilently, currentReportId]);


    const replay = async () => {
        const apiClient = new FathomClient(await getAccessTokenSilently());
        const { data } = await apiClient.put(`{clientId}/programs/` + programId + `/reports/` + currentReportId + '/replay', {});
        navigate(0);
    }


    const goBack = () => {
        navigate("/programs/" + programId + "/reports/");
    }

    const preProgram = () => {
        return report.evaluationPoint.toLowerCase() == "preprogram";
    }

    const syncing = () => {
        return report.status !== "Complete";
    }

    const isError = (status: string) => {
        return (status || "").toLowerCase().indexOf("error") > -1;
    }

    const statusMap = (status: string) => {
        if (report.status == "QuestionAnalysis") {
            return "Analysing questions"
        }

        if (report.status == "OutcomeAnalysis") {
            return "Analysing outcome"
        }

        if (report.status == "ReportGen") {
            return "Generating final report"
        }

        if (report.status == "BatchError") {
            return "Bacthing Error"
        }

        if (report.status == "IndexError") {
            return "Indexing error"
        }

        if (report.status == "QuestionError") {
            return "Question Analysis Error"
        }

        if (report.status == "OutcomeError") {
            return "Outcome Analysis Error"
        }

        if (report.status == "ModuleError") {
            return "Module Analysis Error"
        }

        if (report.status == "ReportError") {
            return "Report Generation Error"
        }



        return status;
    }
    return (
        <div>
            <br />
            {env == "Dev" && <Button onClick={() => replay()} sx={{ opacity: syncing() ? "0" : "0.6", textTransform: 'none' }}>Replay</Button>}
            <Grid container spacing={4}>
                {syncing() &&
                    <Grid item xs={12} sx={{
                        marginTop: "calc(5% + 60px)",
                        bottom: '50%',
                        width: "100%",
                    }}>
                        {env == "Dev" && <Button onClick={() => replay()} sx={{ opacity: !isError(report.status) ? "0" : "0.6", textTransform: 'none' }}>Replay</Button>}
                        <>
                            <Container>
                                {isError(report.status) &&
                                    <>
                                        <br />
                                        <h4>We&apos;ve ran into an issue</h4>
                                        <Typography variant="subtitle2">Issue: {statusMap(report.status)}</Typography>
                                        <br />
                                        <br />
                                        <Typography variant="subtitle2">You can go back, click edit and upload your data again. <br /><br /> Quote report id : <b>{report.id}</b> to the team if you need more help.</Typography>
                                        <br />
                                        <br />

                                    </>
                                }
                                {!isError(report.status) &&
                                    <>
                                        <br />
                                        <br />
                                        <h4>Analysis in progress</h4>
                                        <LinearProgress color="secondary" />
                                        <br />
                                        <Typography variant="subtitle2">Current Status: {statusMap(report.status)}</Typography>
                                        <br />
                                        <Typography variant="subtitle2">This page will reload when analysis is complete</Typography>
                                        <br />
                                    </>
                                }

                            </Container>
                        </>
                    </Grid>
                }
                {!syncing() && <>
                    <br />
                    {loading &&
                        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", marginBottom: "20px" }} >
                            <LinearProgress color="secondary" />
                            <br />
                            <br />
                            <br />
                        </Paper>
                    }
                </>}
            </Grid>
            {!syncing() && !loading &&
                <ViewQuestionsReport report={report} />
            }
            <br />
        </div>
    );
}
